import React from 'react'
import * as styles from './WeaponDetail.module.css'
import data from './Data'
import Img from '../Img'

const WeaponDetail = (props) => {
  const { CDN_ROOT, rarity } = props

  const rename = (str) => {
    return str.replace(/\s/g, '_').replace(/\W+/g, '').toLowerCase()
  }

  const getItem = (name) => {
    return `${CDN_ROOT}unkilled/images/weapons/done/${rename(name)}_medium.png`
  }
  return (
    <div className={styles.title}>
      {data[rarity] ? (
        data[rarity].map((weapon, index) => {
          return (
            <div className={styles.weapon__main} key={weapon + index}>
              <div className={styles.weapon}>
                <div className={styles.weapon__img}>
                  <Img src={getItem(weapon.name)} alt={weapon} />
                </div>
                <div className={styles.weapon__name}>{weapon.name}</div>
                <div className={styles.weapon__desc}>{weapon.desc}</div>
              </div>
            </div>
          )
        })
      ) : (
        <>
          <h1>Oops! Weapon data not loaded properly :(</h1>
        </>
      )}
    </div>
  )
}

export default WeaponDetail
