const data = {
  common: [
    {
      name: 'AK47',
      desc: 'The old standby. Throw it in a bog, let it set for 30 years, pull it out…still fires reliably.',
    },
    {
      name: 'M240',
      desc: 'The machine gun that replaced the ancient M60. Made by Fabrique Nationale. Uncommon',
    },
    {
      name: 'CBJ-MS',
      desc: 'This unique Swedish weapon is best known for its adaptability. It can be used as a personal defense weapon, assault rifle and with the proper attachments even a light support weapon. This flexibility makes it a weapon of choice for any skilled zombie hunter out there.',
    },
    {
      name: 'Ka-bar Knife',
      desc: 'KA-BAR or a combat knife is a standard issue army knife used not only for combat but also for utility. It is also your best friend when your weapon malfunctions or you run out of bullets.',
    },
    {
      name: 'PP2000',
      desc: 'This short sub-machine gun of Russian design offers extreme mobility due to its low weight. In combination with its impressive fire rate, it is a weapon of choice for many law enforcement and even special operations units. In the hands of a skilled soldier like Joe it becomes a surgical tool and every zombie’s nightmare.',
    },
    {
      name: 'M16A1',
      desc: 'One of the most recognized weapons in the world and the cornerstone of US military arsenal. M16A1 made its debut in the 60s in the Vietnam war. Since then it was adopted by many armies around the world. It can also be considered a predecessor to a more advanced M4 Carbine. Today it may not be the most advanced weapon in the world but it gets the job done. Bloody well done in fact. Emphasis on the “bloody” part.',
    },
    {
      name: 'LSAT',
      desc: 'LSAT stands for Light Small Arms Technologies. As the name suggests, LSAT is way lighter than M240 while offering the same level of reliability. With impressive 650 fired rounds per minute it is one of the most effective zombie killing machine guns.',
    },
    {
      name: 'Tomahawk',
    },
    {
      name: 'Saiga 12',
      desc: 'This 12 gauge semi-automatic shotgun combines the advantages of a box-fed, gas operated assault rifle and the stopping power of standard 12 gauge shotgun. The result is this outstanding beast of a gun for close quarters combat with easy reload and high damage output. Just make sure you are standing behind it and not in front.',
    },
    {
      name: 'PP2000 Tactical',
      desc: 'This version of the PP-2000 sub-machine gun uses tactical attachments like a silencer, holographic sights, tactical flashlight and retractable stock. It is also made from a prototype lightweight materials which offers even more mobility than standard PP-2000. This masterpiece of a gun is excellent for covert ops where keeping a low profile is a necessity.',
    },
    {
      name: 'M24',
      desc: 'Sniper Weapon System M24 is a military and police version of Remington 700 Sniper Rifle. US Army promoted this rifle to become a standard sniper rifle. And for a good reason. SWS M24 offers remarkable precision with its 10x42mm fixed power scope and bolt-action mechanism. So yeah, say goodbye to your heads zombies!',
    },
    {
      name: 'M16A2',
      desc: 'An upgraded version of beloved M16A1 made its first appearance in first half of the 80s. M16A2 was more durable, precise and allowed easy modifications like adding scopes, custom magazines or grenade launchers. If you want precision and elegance, M16A2 is the gun for you.',
    },
    {
      name: 'AX308',
      desc: 'An incredibly powerful and precise weapon with high enough caliber to shoot limbs off with ease. It is no wonder because AX-308 belongs to the most powerful precision rifles in the world. Nothing says “Good night” like a well aimed shot between those shining eyes from a mile away.',
    },
    {
      name: 'S&W TRR8 Tactical',
      desc: 'Sometimes you want to go into battle looking fancy. Nothing wrong with that. If you do, we recommend Smith & Wesson 327 TRR8 Tactical. This revolver is a side-arm on steroids. Its laser sights, ergonomic grip and improved barrel mean better accuracy, damage and mobility. Plus it looks really damn sexy.',
    },
  ],

  uncommon: [
    {
      name: 'M1928 Thompson',
      desc: 'An infamous “Tommy Gun” was once a weapon of choice of soldiers, law enforcement agencies but also of Prohibition-era gangsters. Thompson uses .45 ACP cartridges which mean it packs a punch. If you add a decent accuracy, an impressive rate of fire and a drum magazine you get a lethal dose of cool.',
    },
    {
      name: 'Tomahawk',
      desc: 'If you prefer to keep to keep things up-close and personal but consider dual wielding tomahawks too messy, try tactical tomahawk. A lighter double edged version of this awesome native weapon will put a smile on your face.',
    },
    {
      name: 'Simonov SKS',
      desc: 'Do you want to fire something that leaves holes in zombies the size of tangerines while feeling the touch of past? Simonov SKS should do the trick. After the Second World War this semi-automatic rifle was so popular that some prefered it over AK-47. Today the gun so incredibly difficult to find that only the real enthusiast are able to put their hands on it. Are you one of them?',
    },
    {
      name: 'AX308 Tactical',
    },
    {
      name: 'CZ805 Bren A1',
      desc: 'CZ 805 is a rifle that represents the 21st century. Made by Czech engineers, this gun is everything you need in a weapon. Precise, powerful, reliable and stylish. Performing exceptionally well under any situation. Mowing down undead horde included.',
    },
    {
      name: 'Dragunov SVDK',
      desc: 'More powerful and precise version of the standard Dragunov sniper rifle. Model SDVK uses powerful 9.3x64mm cartridges and lays waste to anything that moves from an admirable distance.',
    },
    {
      name: 'Glock 17 Kit',
    },
    {
      name: 'M240 Tactical',
      desc: 'This version of the M240 is equipped with infantry modification kit which makes the machine gun more infantry friendly. With flash suppressor, front sight, carrying handle for the barrel, a buttstock, infantry length pistol grip, bipod and rear sight assembly this machine gun becomes the worst nightmare of those who stand on the wrong side of it.',
    },
  ],

  rare: [
    {
      name: 'LSAT Tactical',
      desc: 'Just imagine your favourite light machine gun even lighter, more powerful and precise. All is possible with numerous upgrades and modifications on LSAT Tactical. Zombies beware!',
    },
    {
      name: 'Luger P08',
      desc: 'The result of German precision, the Pistole Parabellum 1908 is considered one of the best weapons of both World Wars. Its distinctive shape and legacy make it a priceless piece in any collector’s set. Only a handful of people can actually use it in combat. You have that privilege so show some respect.',
    },
    {
      name: 'AK47 AKM',
      desc: 'Advanced variant of the world’s most recognizable and reliable gun. This rifle designed by Mikhail Kalashnikov is not only powerful and accurate, it shoots no matter what. Water, mud, corrosion or zombie blood. Hot lead will fly. That is the reason why this modernized version of AK-47 is considered to be one of the best weapons to have during any kind of apocalypse. Especially the zombie one where there is never lack of moving targets.',
    },
    {
      name: 'G36c',
    },
    {
      name: 'PP2000 Dual',
      desc: 'Dual version of this short sub-machine gun of Russian design offers double pleasure.',
    },
    {
      name: 'Dual Tomahawk',
      desc: 'Do you want to feel like a hardcore hunter? This weapon of the Algonquian Native Americans will do the trick. Oh yeah, and you get to wield one in each hand. How cool is that?',
    },
    {
      name: 'Saiga 12k-040',
      desc: 'An improved model of SAIGA-12. Drum magazine, longer barrel with improved muzzle and holographic sights. If zombies could, they would soil themselves.',
    },
    {
      name: 'S&W TRR8 Dual',
      desc: 'What is cooler than one huge revolver? That’s right! Two huge revolvers! With these guns you will experience twice the fun with twice the style.',
    },
    {
      name: 'H&K G36C',
    },
  ],

  epic: [
    {
      name: 'Barrett M82A2',
      desc: 'For sniper/ anti vehicle weapons, there’s no better. You’ll likely be referred to as “God”.',
    },
    {
      name: 'Maschinengewehr 42',
      desc: 'The Granddaddy of them all. Still a masterpiece of killy-death.',
    },
    {
      name: 'G36 Spec Ops',
    },
    {
      name: 'Mossberg 590',
    },
    {
      name: 'CBJ-MS PDW',
      desc: 'When Joe wanted his CBJ-MS to pack more punch he bought some serious modifications. Silencer, extended magazine, forward grip and aimpoint sights. He was very happy with the result. Zombies, not so much.',
    },
    {
      name: 'Remington Police',
      desc: 'Remington 870 Police is a customized version of a standard 870. These special models feature stronger sear spring, carrier latch spring, forged steel extractor and shorten forend. These features offer many different advantages a skilled zombie hunter sure appreciates.',
    },
    {
      name: 'M16A4',
      desc: 'The fourth generation of M16 series and the pinnacle of american weapon engineering. “M4” is a standard issue weapon of the US Marine Corps. Heavily modifiable and reliable. Precise and powerful. Plus the skin looks pretty damn awesome.  Zombie killing can’t get more american than this. Hell yeah!',
    },
    {
      name: 'Glock 17 Tactical',
    },
    {
      name: 'Hunting Bow',
    },
    {
      name: 'LSAT SpecOps',
      desc: 'Just imagine your favourite light machine gun even lighter, more powerful and precise. All is possible with numerous upgrades and modifications on this version of LSAT.',
    },
  ],
}

export default data
