import React from 'react'
import Layout from '../../components/Layout'
import WeaponDetail from '../../components/weapon_detail/WeaponDetail'

const CDN_ROOT = 'https://mfg-home.b-cdn.net/'

const Weapon = (props) => (
  <Layout CDN_ROOT={CDN_ROOT}>
    <WeaponDetail rarity={'uncommon'} {...props} CDN_ROOT={CDN_ROOT} />
  </Layout>
)

export default Weapon
